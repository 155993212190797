<template>
    <div>
        <v-toolbar
            dense
            tabs
            color="primary darken-2"
            dark
            class="page_title"
        >
            <v-toolbar-title class="pa-0 pr-0 mt-1">
                <v-layout row >
                    <v-flex xs10 sm11 text-xs-left>
                        <a href="javascript: history.go(-1)" class="pa-0">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-arrow-left headline"></i>
                            </v-btn>
                        </a>
                        Terms and Conditions
                    </v-flex>
                    <v-flex xs2 sm1 text-xs-right>
                        <router-link :to="$store.state.close_url" class="pa-0 text-xs-right">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-close headline"></i>
                            </v-btn>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <v-slide-y-transition mode="out-in">
                        <div>
                            <v-card color="white">
                                <v-card-text>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem velit voluptate quis laborum iste! Esse optio, ab praesentium ipsum dolores animi fuga deleniti iure dolor possimus, itaque beatae illum in.</v-card-text>
                            </v-card>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Terms and Conditions"
            this.$store.commit('setCloseURL', "/")
        },

    }
</script>
